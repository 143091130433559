import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import RegionsRenderer from './RegionsRenderer';

const Container = styled.div`
  height: 500px;
  min-width: 100%;
`;

const dataToColumnDefs = (gridApi, setSelected) => {
  const columns = ['name', 'email', 'username'].map(field => {
    return {
      headerName: field.toUpperCase(),
      field,
      resizable: true,
      sortable: true,
      filter: true,
      checkboxSelection: field === 'name',
      floatingFilter: true,
    };
  });

  columns.push({
    headerName: 'REGION',
    field: 'region',
    suppressNavigable: true,
    suppressMovable: true,
    suppressMenu: true,
    headerTooltip: 'Choose the region where the VM should spawn',
    cellRenderer: 'regionsRenderer',
    cellRendererParams: {
      gridApi,
      setSelected,
    },
  });

  return columns;
};

const Table = ({ studentRegistry, setSelected, studentsInClass }) => {
  const [gridApi, setGridApi] = useState(null);
  const studentIdsInClass = studentsInClass.map(({ studentId }) => studentId);

  const isRowSelectable = node => {
    return node.data
      ? !studentsInClass
          .map(({ studentId }) => studentId)
          .includes(node.data.studentId)
      : false;
  };

  const disabledRowStyles = ({
    node: {
      data: { studentId },
    },
  }) => {
    if (studentIdsInClass.includes(studentId)) {
      return {
        opacity: 0.3,
        pointerEvents: 'none',
      };
    }
    return {};
  };

  return (
    <Container className="ag-theme-alpine">
      {studentRegistry && studentRegistry.length > 0 && (
        <AgGridReact
          onGridReady={({ api }) => {
            setGridApi(api);
            api.sizeColumnsToFit();
          }}
          onRowSelected={() => {
            const studentArr = [];

            gridApi
              .getSelectedRows()
              .forEach(({ studentId, region = 'ap-southeast-1' }) => {
                studentArr.push({ studentId, region });
              });

            setSelected(studentArr);
          }}
          suppressRowClickSelection
          getRowStyle={disabledRowStyles}
          frameworkComponents={{ regionsRenderer: RegionsRenderer }}
          columnDefs={dataToColumnDefs(gridApi, setSelected)}
          rowData={studentRegistry.map(student => {
            if (studentIdsInClass.includes(student.studentId)) {
              const studentIndex = studentIdsInClass.indexOf(student.studentId);

              return {
                ...student,
                name: `${student.name} (Currently in class with ${studentsInClass[studentIndex].instructor.name})`,
              };
            }
            return student;
          })}
          rowSelection="multiple"
          isRowSelectable={isRowSelectable}
          animateRows
        />
      )}
    </Container>
  );
};

Table.defaultProps = {
  studentRegistry: [],
  studentsInClass: [],
  setSelected: () => {},
};

Table.propTypes = {
  setSelected: PropTypes.func,
  studentRegistry: PropTypes.arrayOf(PropTypes.object),
  studentsInClass: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
