import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import WS from '../../../utils/WS';
import $ from '../../../styles/global';
import Utils from '../../../utils';
import { setInstructorSession } from '../../../actions';
import LoadingBar from './LoadingBar';
import useActions from '../../../utils/useActions';
import TYPES from '../../../actions/types';
import Table from './Table';
import {
  createClassGQL,
  getClassGQL,
  getAllStudentsGQL,
} from '../../../apollo/instructor';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled.div`
  padding: ${$.layout().padding2}px;
  width: 80%;
`;

const Title = styled.div`
  font-size: 30px;
  text-align: center;
  font-family: Dosis Regular;
  color: ${$.color.blue3};
  margin-bottom: ${$.layout().margin4}px;
`;

const SubmitButton = styled.button`
  ${({ disabled }) => `
    min-width: 60px;
    width: 100%;
    padding: ${$.layout().padding1}px;
    border-radius: 5px;
    background-color: ${$.color.blue5};
    border: none;
    box-shadow: ${$.dropShadow.normal};
    transition: all 0.25s ease;
    color: ${$.color.white};
    text-transform: uppercase;
    outline: none;
    font-family: Dosis Bold;
    letter-spacing: 1px;

    ${disabled ? `pointer-events: none; opacity: 0.5;` : ''}

    &:hover {
      cursor: pointer;
      background-color: ${$.color.blue4};
      box-shadow: ${$.dropShadow.repressed};
    }
  `}
`;

const ErrMessage = styled.div`
  ${({ show }) => `
    color: ${$.color.red};
    font-size: 16px;
    line-height: 16px;
    margin-top: ${$.layout().margin1}px;
    text-align: center;
    opacity: ${show ? 1 : 0};
  `}
`;

/**
 * Shows pop up before user closes tab.
 * @param {boolean} state - True to set a popup. False to remove popup.
 */
const preventAccidentalClose = state => {
  window.onbeforeunload = state
    ? () => {
        return true;
      }
    : () => {};
};

const CreateClass = () => {
  const [selected, setSelected] = useState([]);
  const [studentRegistry, setStudentRegistry] = useState([]);
  const [studentsInClass, setStudentsInClass] = useState([]);
  const [error, setError] = useState('');
  const [fillProgressBar, setFillProgressBar] = useState(false);
  const [creatingClass, setCreatingClass] = useState(false);
  const [setCurrentSession] = useActions([setInstructorSession]);
  const state = useSelector(({ user }) => {
    return { user };
  });

  /**
   * Checks if the class has been successfully created or not. Keeps pinging every 10s.
   * 1. instructor returns data. Fill progress bar to 100% and
   * wait 10s before redirecting to /instructor/class. Setup the redux state before redirecting.
   * 2. Server returns error. Show error.
   */
  const [getClassStatus] = useLazyQuery(getClassGQL, {
    variables: { instructorId: state.user.id },
    pollInterval: 10000,
    onCompleted: ({ instructor: instructorData }) => {
      if (instructorData) {
        setFillProgressBar(true);
        setTimeout(() => {
          preventAccidentalClose(false);
          setCurrentSession({
            wsInstance: new WS(
              TYPES.INSTRUCTOR_ROLE,
              instructorData.twilioRoomName
            ),
            instructorVM: instructorData.ipAddress,
            twilioRoomName: instructorData.twilioRoomName,
            students: instructorData.enlistedStudents,
          });

          navigate('/instructor/class/');
        }, 8000);
      }
    },
    onError: () => {
      preventAccidentalClose(false);
      setError(
        'Failed to get instances status. Please refresh the page and try again.'
      );
    },
  });

  /**
   * Sends the request to create a class. Returns 'Success' or null upon completion.
   * 1. 'Success' received. Ping getClassStatus every 10s. Set creatingClass to true.
   * 2. null received. Show error.
   * 3. Failed to ping server. Show error.
   */
  const [createClass] = useMutation(createClassGQL, {
    onCompleted: ({ createClassAsync }) => {
      if (createClassAsync === 'Success') {
        getClassStatus();
      } else {
        preventAccidentalClose(false);
        setError(
          'Failed to create class. Please refresh the page and try again.'
        );
      }
    },
    onError: e => {
      console.log(e);
      preventAccidentalClose(false);
      setError(
        'Failed to create class. Please refresh the page and try again.'
      );
    },
  });

  /**
   * Starting point! Get all students to display in a table.
   * 1. getStudentsRegistry has data. Set into students state.
   * 2. No data returned. Show error.
   * 3. Failed to ping server. Show error.
   */
  useQuery(getAllStudentsGQL, {
    onCompleted: ({ getStudentsRegistry, students }) => {
      if (getStudentsRegistry) {
        setStudentRegistry(Utils.addKeys(getStudentsRegistry));
      } else {
        setError(
          'Failed to retrieve student list. Please refresh the page and try again.'
        );
      }

      if (students) {
        setStudentsInClass(students);
      }
    },
    onError: () => {
      setError(
        'Failed to retrieve student list. Please refresh the page and try again.'
      );
    },
  });

  return (
    <Container>
      <Form>
        <Title>
          {creatingClass ? 'Creating a new class' : 'Create a new class'}
        </Title>
        {creatingClass ? (
          <LoadingBar done={fillProgressBar} fail={error !== ''} />
        ) : (
          <>
            <Table
              studentRegistry={studentRegistry}
              setSelected={setSelected}
              studentsInClass={studentsInClass}
            />
            <SubmitButton
              disabled={selected.length === 0}
              onClick={() => {
                setCreatingClass(true);
                preventAccidentalClose(true);
                createClass({
                  variables: { selectedStudents: selected },
                });
              }}
            >
              Create Class
            </SubmitButton>
          </>
        )}
        <ErrMessage show={error !== ''}>{error}</ErrMessage>
      </Form>
    </Container>
  );
};

export default CreateClass;
