import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../styles/global';

const Container = styled.div`
  > select {
    border: 1px solid ${$.color.gray1};
    border-radius: 10px;
    padding: 6px 8px;
    outline: none;
  }
`;

const RegionsRenderer = ({ value, setValue, gridApi, setSelected }) => {
  const [region, setRegion] = useState(value);

  const handleChange = e => {
    const studentArr = [];

    setValue(e.target.value);
    setRegion(e.target.value);

    gridApi
      .getSelectedRows()
      .forEach(({ studentId, region: studentRegion }) => {
        studentArr.push({ studentId, region: studentRegion });
      });

    setSelected(studentArr);
  };

  return (
    <Container>
      <select value={region} onChange={handleChange}>
        <option value="ap-southeast-1">Singapore (Asia Pacific)</option>
        <option value="us-east-1">N. Virginia (US East)</option>
        <option value="us-east-2">Ohio (US East)</option>
        <option value="us-west-1">N. California (US West)</option>
        <option value="us-west-2">Oregon (US West)</option>
        <option value="ap-south-1">Mumbai (Asia Pacific)</option>
        <option value="ap-northeast-1">Tokyo (Asia Pacific)</option>
        <option value="ap-northeast-2">Seoul (Asia Pacific)</option>
        <option value="ap-southeast-2">Sydney (Asia Pacific)</option>
        <option value="ca-central-1">Canada (Central)</option>
        <option value="eu-central-1">Frankfurt (Europe)</option>
        <option value="eu-west-1">Ireland (Europe)</option>
        <option value="eu-west-2">London (Europe)</option>
        <option value="eu-west-3">Paris (Europe)</option>
      </select>
    </Container>
  );
};

RegionsRenderer.defaultProps = {
  setValue: () => {},
  gridApi: {},
  setSelected: () => {},
  value: '',
};

RegionsRenderer.propTypes = {
  setSelected: PropTypes.func,
  gridApi: PropTypes.oneOfType([PropTypes.object]),
  setValue: PropTypes.func,
  value: PropTypes.string,
};

export default RegionsRenderer;
