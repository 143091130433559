import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${$.layout().margin5}px;
  height: 300px;
`;

const Bar = styled.div`
  ${({ pause }) => `
  margin-bottom: ${$.layout().margin2}px;
  width: 300px;
  height: 40px;
  overflow: hidden;
  border: 1px solid ${pause ? $.color.gray3 : $.color.orange4};
  border-radius: ${$.border().radius1}px;
  position: relative;
  ${pause ? '' : 'animation: progress 2s linear infinite;'}
  background-size: 40px 40px;
  background-image: linear-gradient(
    -45deg,
    ${pause ? $.color.gray2 : $.color.orange2} 25%,
    ${pause ? $.color.gray1 : $.color.orange1} 25%,
    ${pause ? $.color.gray1 : $.color.orange1} 50%,
    ${pause ? $.color.gray2 : $.color.orange2} 50%,
    ${pause ? $.color.gray2 : $.color.orange2} 75%,
    ${pause ? $.color.gray1 : $.color.orange1} 75%,
    ${pause ? $.color.gray1 : $.color.orange1}
  );

  @keyframes progress {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 40px 0px;
    }
  }
  `}
`;

const Thumb = styled.div`
  ${({ width }) => `
    width: ${width}%;
    background-color: ${$.color.white};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  `}
`;

const Percentage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  line-height: 38px;
  font-size: 20px;
  color: ${$.color.blue4};
  font-family: Dosis Bold;
`;

const Message = styled.div`
  text-align: center;
  color: ${$.color.blue4};
  font-size: 16px;
`;

const randomize = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

/**
 * Progressively fills the bar with a random number generator.
 * 1. Repeatedly calls setTimeout with a different millisecond generated by randomize().
 * 2. The timeout will fill the progress bar with a random % everytime until ~25% is left.
 * 3. If done is true, then progress bar automatically filled to 100% and stopped.
 * 4. If fail is true, then progress bar will pause and show ? as %.
 */
const LoadingBar = ({ done, fail }) => {
  const [progress, setProgress] = useState(100);
  const [progressMessage, setProgressMessage] = useState(
    'Pinging our database...'
  );

  /**
   * Starting point! Begin randomizing the progress with a recursion function.
   */
  useEffect(() => {
    let storeTimeout;

    const timeout = () => {
      const randomSeconds = randomize(1, 6);

      storeTimeout = setTimeout(() => {
        setProgress(prev => {
          let newProgress = prev;

          if (newProgress > 25) {
            timeout();
            newProgress = prev - randomize(1, 20);
          }

          return newProgress;
        });
      }, randomSeconds * 1000);
    };

    timeout();

    return () => {
      clearTimeout(storeTimeout);
    };
  }, []);

  /**
   * Listens to progress state and set loading message. Stop once API call returns an error status.
   */
  useEffect(() => {
    if (fail) {
      return;
    }

    if (progress >= 60 && progress <= 90) {
      setProgressMessage('Creating instances...');
      return;
    }

    if (progress >= 30 && progress <= 59) {
      setProgressMessage('Warming up instances...');
      return;
    }

    if (progress >= 1 && progress <= 29) {
      setProgressMessage('Almost ready. Waiting for confirmation...');
    }
  }, [fail, progress]);

  /**
   * Listens to done or fail props to set progress bar to 100% or 0%.
   */
  useEffect(() => {
    if (done) {
      setProgressMessage('All instances prepped. Redirecting...');
      setProgress(0);
      return;
    }

    if (fail) {
      setProgressMessage('Something went wrong.');
      setProgress(0);
    }
  }, [done, fail]);

  return (
    <Container>
      <Bar pause={fail}>
        <Thumb width={progress} />
        <Percentage>{`${fail ? '?' : `${100 - progress} %`}`}</Percentage>
      </Bar>
      <Message>{progressMessage}</Message>
    </Container>
  );
};

LoadingBar.defaultProps = {
  done: false,
  fail: false,
};

LoadingBar.propTypes = {
  done: PropTypes.bool,
  fail: PropTypes.bool,
};

export default LoadingBar;
