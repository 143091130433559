import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SEO from '../../components/seo';
import useActions from '../../utils/useActions';
import Utils from '../../utils';
import { setUser } from '../../actions';
import Loading from '../../components/Loading';
import { getClassGQL } from '../../apollo/instructor';
import $ from '../../styles/global';
import GoToClassButton from '../../components/instructor/Dashboard/GoToClassButton';
import CreateClass from '../../components/instructor/CreateClass';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  font-size: 20px;
  line-height: 1.3em;
  text-align: center;
`;

const ErrMessage = styled(Message)`
  color: ${$.color.red};
`;

const StyledGoToClassButton = styled(GoToClassButton)`
  margin-top: ${$.layout().margin3}px;
  margin-left: auto;
  margin-right: auto;
`;

const Create = () => {
  const [hasClass, setHasClass] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [setCurrentUser] = useActions([setUser]);
  const state = useSelector(({ user }) => {
    return { user };
  });
  /**
   * Uses callbacks to toggle components. This is to prevent a remount when a child component
   * has their own Apollo query running and remounts to refresh child component.
   */
  const [getClass] = useLazyQuery(getClassGQL, {
    variables: {
      instructorId: state.user.id,
    },
    onCompleted: ({ instructor }) => {
      setLoading(false);
      setHasClass(instructor !== null);
    },
    onError: ({ message }) => {
      setLoading(false);
      setError(message);
    },
  });

  /**
   * Entry point. Kick out anyone who is not an instructor, then check if we have a class or not before proceeding.
   * Set redux to inClass: false to hide/show UI elements.
   */
  useEffect(() => {
    Utils.redirect(state.user.role);
    getClass();
    setCurrentUser({ inClass: false });
  }, []);

  return (
    <>
      <SEO
        title="New Class | SG Code Campus Online Platform App"
        description="Learn to code in SG Code Campus's very own online learning platform."
      />
      {loading && (
        <Container>
          <Loading />
        </Container>
      )}
      {error && (
        <Container>
          <ErrMessage>
            {`An error occurred: `}
            <br />
            {error}
          </ErrMessage>
        </Container>
      )}
      {hasClass ? (
        <Container>
          <Message>
            <div>
              You have an ongoing class now.
              <br />
              Please end the class first before creating a new one.
            </div>
            <StyledGoToClassButton />
          </Message>
        </Container>
      ) : (
        <CreateClass />
      )}
    </>
  );
};

export default Create;
